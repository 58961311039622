<template>
  <div class="agreement">
		<div class="block"></div>
		<h3>免责声明</h3>
		<div>
			<p><span>1)</span>本平台由默沙东开发并提供，旨在为医疗卫生专业人士提供便捷的工具以获取最新指南推荐、临床实用工具、领域前沿进展、疾病诊断等科学信息，所有内容均参考已公开发表的医学文献并标注来源。为便于用户阅读，本平台对部分英文文献或权威网站中的英文内容进行了翻译。若您对翻译内容有异议，请参考原文内容。本平台提供的资料如与原文不符，请以原文为准。</p>
			<p><span>2)</span>本平台的内容仅作为科学信息参考，并仅用于医疗卫生专业人士间的学术交流之目的，请勿转发或用于其他商业用途。提供信息仅作为参考，无法替代医疗卫生专业人士的独立、完整的分析、评估、诊断、风险评估、治疗或其他医疗活动。</p>
			<p><span>3)</span>本平台可能会出现某些药品信息，和/或提到相关诊断、治疗方法，医疗卫生专业人士做出的任何与治疗有关的决定应基于患者的具体情况，并应参照国家药品监督管理局批准的药品说明书及国家卫生和健康委员会以及相关政府部门批准使用的指南或规定。默沙东不通过本平台提供任何医疗活动建议。</p>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
		};
  },
  created() {
	},
  mounted() {
		this.$wxShare._wxHideMenu()
	},
  methods: {
	}
};
</script>

<style scoped lang="scss">
h3{
	text-align: center;
	font-size: 34px;
	margin-top: 20px;
}
.block{
	width: 100%;
	height: 50px;
}
.agreement{
	color: #9ABBB9;
	margin: 0 40px;
	font-size: 28px;
	// padding-bottom: 640px;

	>div>p{
		display: flex;
		margin-top: 50px;
		span{
			flex-shrink: 0;
			margin-right: 10px;
			margin-top: 5px;
		}
	}
}
a{
	color: #2D48F8;
}
</style>
